<script setup>
defineProps({
    transaction: {
        type: Object
    },
    darkmode: {
        type: Boolean,
        default: false
    }
});

function formatMoney(v) {
    let total = parseFloat(v);

    return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
}

function formatDate(d){
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

const emit = defineEmits(['pay']);

const payTrasaction = (data) => {
    emit('pay', data);
}

const is_taled = (date, state, darkmode = false) => {
    let data = new Date(date);

    if(data.setDate(data.getDate() + 1) < new Date() && state == 'Unpaid'){
        
        if(darkmode){
            return 'transaction-taled darkmode';
        }

        return 'transaction-taled';
    }
}

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}
</script>

<template>
    <div class="col-12 border-bottom" :class="is_taled(transaction.date, transaction.status, darkmode)">

        <div class="row align-items-center row-transactions">

            <div class="col-10 align-content-between d-flex align-items-center">

                <div class="text-center shadow icon icon-shape align-self-center rounded-circle ms-2 me-3" :style="'background-color: '+transaction.category_color" v-tooltip="{
                            content: transaction.category_name,
                            distance: 15
                        }">
                    <i :class="'fal ' + transaction.category_icon +' row-transactions-icons' " :style="'color:'+getContrastYIQ(transaction.category_color)"></i>
                </div>

                <div>
                    <span class="text-sm">{{ formatDate(transaction.date) }}</span>
                    <h6 class="mt-n1 mb-n1">{{ transaction.description }}</h6>
                    <span class="mb-0 text-md font-weight-bold" :class="transaction.type == 'despesa' ? 'text-danger' : transaction.type == 'receita' ? 'text-success' : ''">{{transaction.type == 'despesa' ? '-': ''}} {{ formatMoney(transaction.amount) }}</span>
                </div>
            </div>
            <div class="col-2">

                <a @click="payTrasaction(transaction)" class="me-3 cursor-pointer">
                    <i 
                        class="fal fa-thumbs-up row-transactions-icons-actions"
                        :class="darkmode ? 'text-white-50' : 'text-black-50'"
                        v-tooltip="{
                            content: transaction.type == 'despesa' ? transaction.status == 'Unpaid' ? 'Pagar' : 'Pago' : transaction.status == 'Unpaid' ? 'Receber' : 'Recebido',
                            distance: 15
                        }"
                    ></i>
                </a>

            </div>           
            

        </div>

    </div>
</template>

<style>

    .row-transactions-icons{
        font-size: 20px !important;
    }

    .row-transactions-icons-actions{
        font-size: 26px;
    }
</style>