<script setup>

import { useStore } from "vuex";
import { onBeforeMount } from 'vue';
import bootstrap from "bootstrap/dist/js/bootstrap";
import axios from "axios";
import validSession from "../assets/js/valid-session";
import { toast } from 'vue3-toastify';
import loadGif from "@/assets/img/loading.gif"
import ObjetivesRow from "./components/ObjetivesRow.vue";
import { computed } from "vue";

const colors = {
    1: '#000000',
    2: '#6A5ACD',
    3: '#00CED1',
    4: '#006400',
    5: '#DAA520',
    6: '#696969',
    7: '#4682B4',
    8: '#8B0000',
    9: '#556B2F',
    10: '#D2691E',
    11: '#B8860B',
    12: '#9ACD32',
    13: '#FF69B4',
    14: '#C71585',
    15: '#4169E1',
    16: '#6E39B0',
    17: '#A3E2EC',
    18: '#3727C8',
    19: '#3DD025',
    20: '#E3B41C',
    21: '#A7368E',
    22: '#D1AE6A',
    23: '#78CB99',
    24: '#928577',
    25: '#EB6618',
}

const value_objetive = {
    value: 0
};

const money = {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }

let icons = [];

const getIcons = async () => {

    axios.get('icons')
    .then(res => {
        
        if(res.status == 200){

            icons = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

onBeforeMount(() => {

    validSession.isLogged();

    store.state.transaction_value = {
        value: 0
    }

    store.state.objetive_icon = 'fa-receipt';
    store.state.objetive_color = '#000'
    store.state.objetive_name = '';
    store.state.objetive_target = 0;
    store.state.objetive_discount = 0;
    store.state.objetive_account = 0;
    store.state.objetive_id = '';

    store.state.accounts = [];
    store.state.objetives = [];
    
    store.state.loadingObjetives = true;
    store.state.savingObjetive = false;

    store.state.savingTransaction = false;

    store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
        accountHasError: false,
        accountMessageError: '',
    };

    store.state.deleted_category = {};
    store.state.destiny_category = 0;

    store.state.toArchive = {
        icon: '',
        name: '',
    };

    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    store.state.current_date = year+ '-' + (month < 10 ? '0'+month : month) + '-' + (day < 10 ? '0'+ day : day);

    store.state.transation = {
        type: '',
        amount: 0,
        date: store.state.current_date,
        objetive_id: 0,
        objetive_name: ''
    }

    store.state.modalColor = '';

    getObjetives();
    getIcons();
    getAccounts();
    
});

const clearFields = () => {
    store.state.objetive_icon = 'fa-receipt';
    store.state.objetive_color = '#000'
    store.state.objetive_name = '';
    store.state.objetive_target = 0;
    store.state.objetive_id = '';
}

const openModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.show();

}

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

const getAccounts = async () => {

    axios.get('accounts')
    .then(res => {
        
        if(res.status == 200){

            store.state.accounts = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getObjetives = async () => {

    store.state.loadingObjetives = true;

    axios.get('objetives')
    .then(res => {
        
        if(res.status == 200){

            store.state.objetives = res.data;
            store.state.loadingObjetives = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

const submit_objetive = async () => {

    let data = new FormData();

    store.state.validation.nameHasError = false;
    store.state.validation.accountHasError = false;

    store.state.objetive_target = value_objetive.value.replace('R$ ', '');

    data.append('name', store.state.objetive_name);
    data.append('icon', store.state.objetive_icon);
    data.append('color', store.state.objetive_color);
    data.append('target', store.state.objetive_target);
    data.append('discount', store.state.objetive_discount == true ? 'Yes' : 'No');
    data.append('account_id', store.state.objetive_discount == true ? store.state.objetive_account : 0);

    let url = '/objetives';
    let toast_message = 'Objetivo criado com sucesso!';

    if(store.state.objetive_id != ''){
        url = '/objetives/update/'+store.state.objetive_id;
        toast_message = 'Objetivo atualizado com sucesso!';
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'name'){
                    store.state.validation.nameHasError = true;
                    store.state.validation.nameMessageError = value[1];
                }

                else if(value[0] == 'parent_id'){
                    store.state.validation.parentHasError = true;
                    store.state.validation.parentMessageError = value[1];
                }


            })

        }
        else{

            store.state.validation = {
                nameHasError: false,
                nameMessageError: '',
                accountHasError: false,
                accountMessageError: '',
            };

            closeModal('addObjetiveModal');

            clearFields();

            getObjetives();

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

        }

    })
    .catch(err => {
    console.error(err); 
    })

}

const editObjetive = (data) => {

    value_objetive.value = formatMoney(data.target);

    store.state.objetive_icon = data.icon;
    store.state.objetive_color = data.color
    store.state.objetive_name = data.name;
    store.state.objetive_target = data.target;
    store.state.objetive_discount =  data.discount == 'Yes' ? true : false;
    store.state.objetive_account = data.account_id;
    store.state.objetive_id = data.id;

}

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}

const deleteObjetive = async (objetive) => {

    store.state.toArchive = {
        icon: objetive.icon,
        name: objetive.name,
    };

    openModal('deleteObjetive');

    var confirmButton = document.getElementById('confirmExclusionObjetiveButton');
    confirmButton.addEventListener('click', async function () {
    // do something...

        //console.log('clicou');

        let url = '/objetives/delete/'+objetive.id;
        let toast_message = 'Objetivo excluído com sucesso!';

        await axios.post(url)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('deleteObjetive');
                
                getObjetives();

            }

        })
        .catch(err => {
            console.error(err); 
        })
        

    })

}

const setType = (data, type) => {

    if(type == 'retirada'){
        store.state.modalColor = '-dark';
    }
    else if(type == 'deposito'){
        store.state.modalColor = '-success';
    }

    store.state.transation.type = type;
    store.state.transation.objetive_id = data.id;
    store.state.transation.objetive_name = data.name;

}

const clearFieldsTransaction = () => {

    store.state.transation = {
        type: '',
        amount: 0,
        date: store.state.current_date,
        objetive_id: 0,
        objetive_name: ''
    }

    store.state.transaction_value = {
        value: 0
    }

}

const submitTransaction = async () => {

    store.state.savingTransaction = true;

    let data = new FormData();

    store.state.transation.amount = store.state.transaction_value.value.replace('R$ ', '');

    //console.log(store.state.transation.amount);

    data.append('type', store.state.transation.type);
    data.append('amount', store.state.transation.amount);
    data.append('date', store.state.transation.date);
    data.append('objetive_id', store.state.transation.objetive_id);

    let url = '/objetives/transactions';
    let toast_message = 'Transação criado com sucesso!';

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'description'){
                    store.state.validation.descHasError = true;
                    store.state.validation.descMessageError = value[1];
                }
                else if(value[0] == 'account'){
                    store.state.validation.accountError = true;
                    store.state.validation.accountMessageError = value[1];
                }
                else if(value[0] == 'category'){
                    store.state.validation.categorryError = true;
                    store.state.validation.categoryMessageError = value[1];
                }

            })

            store.state.savingTransaction = false;

        }
        else{

            store.state.validation = {
                descHasError: false,
                descMessageError: '',
            };

            closeModal('addTransaction');

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            store.state.savingTransaction = false;

            getObjetives();
            clearFieldsTransaction();

        }

    })
    .catch(err => {
        console.error(err); 

        toast('Não foi possível realizar a operação, tente mais tarde (Erro '+err.response.status+')', {
            autoClose: 1000,
            theme: "auto",
            type: "error",
            transition: "slide"
        });

        store.state.savingTransaction = false;

    })

}

</script>

<template>
    <div class="py-4 container-fluid">

        <div class="row">
            <div class="col-12"></div>
        </div>

        <div class="card">

            <div class="card-header pb-0">
                <div class="row align-items-center">
                    <div class="col-6">
                        <h6>Objetivos</h6>
                    </div>
                    <div class="col-6 text-end">
                        <a 
                            class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" 
                            type="button" 
                            id="dropdownMenuButton1"
                            @click="openModal('addObjetiveModal')"
                            >
                            <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                        </a>
                        
                    </div>
                </div>
            </div>

            <div class="card-body px-0 pt-0 pb-2">

                <div class="col-12">

                    <div class="row">

                        <div class="col-12 text-center mt-4" v-if="store.state.loadingObjetives">
                            <img :src="loadGif" alt="" width="60px">
                        </div>
                        <div class="col-12 text-center mt-4" v-if="store.state.objetives.length == 0 && !store.state.loadingObjetives">
                            <p>Nenhum objetivo cadastrado.</p>
                        </div>
                        <div class="col-12 mt-4 mb-4" v-if="!store.state.loadingObjetives">

                            <ObjetivesRow
                                v-for="(objetive, index) in store.state.objetives" :key="index"
                                :objetive="objetive"
                                :id="objetive.id"
                                :darkmode = darkMode
                                @edit="editObjetive"
                                @setType="setType"
                                @delete="deleteObjetive"
                            />

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="modal fade" id="addObjetiveModal" tabindex="-1" aria-labelledby="addObjetiveModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">

                        <div class="col-10">
                            <b>
                                Criando um Objetivo
                            </b>
                        </div>

                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-11 mx-auto">

                            <div class="row align-items-center mt-5">
                                <div class="col-3 category-create-icon-div">

                                    <div class="text-center shadow icon icon-shape align-self-center rounded-circle ms-2 me-3" :style="'background-color: '+store.state.objetive_color">
                                        <i :class="'icon-create fal ' + store.state.objetive_icon" :style="'color:'+getContrastYIQ(store.state.objetive_color)"></i>
                                    </div>

                                </div>
                                <div class="col-9 mb-3">

                                    <label for="ObjetiveName">Nome do objetivo</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="ObjetiveName"
                                        name="ObjetiveName"
                                        placeholder="Nome do objetivo"
                                        v-model="store.state.objetive_name"
                                        :class="store.state.validation.nameHasError ? 'is-invalid' : ''"
                                    >
                                    <div id="objetiveNameFeedback" class="invalid-feedback">
                                        {{ store.state.validation.nameMessageError }}
                                    </div>                                    

                                </div>
                                <div class="col-12 mb-3">
                                    <label for="transactionAmount">Meta do objetivo</label>
                                    <input 
                                        type="tel" 
                                        class="form-control form-control-lg" 
                                        id="objetiveAmount"
                                        name="objetiveAmount"
                                        v-model.lazy="value_objetive.value" 
                                        v-money="money"
                                    >
                                </div>
                                <div class="col-12 mb-1">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="store.state.objetive_discount">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Criar transação automatica na conta
                                        </label>
                                    </div>
                                </div>
                                <div v-if="store.state.objetive_discount" class="col-12 mb-3">
                                    <label for="categoryName">Criar transação na conta:</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta" 
                                        v-model="store.state.objetive_account"
                                        :class="store.state.validation.accountError ? 'is-invalid' : ''"
                                        id="parentSelect"
                                    >
                                            <option value="0" selected disabled>Selecione a conta</option>
                                            <option
                                                v-for="(account, index) in store.state.accounts" :key="index"
                                                :value="account.id"
                                            >
                                                {{ account.name }}
                                            </option>
                                    </select>
                                    <div id="accountSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.accountMessageError }}
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha um icone</p>
                                </div>

                                <div class="col-12 icon-list">

                                    <div class="row justify-content-center">

                                        <div
                                            v-for="(icon, index) in icons" :key="index"
                                            @click="store.state.objetive_icon = icon.name"
                                            class="color-picker rounded-circle d-flex align-items-center"
                                            :class="darkMode ? 'text-white' : 'text-dark'" 
                                        >
                                            <i :class="'ms-n1 fal '+icon.name"></i>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha uma cor</p>
                                </div>
                                
                                <div
                                    v-for="(color, index) in colors" :key="index"
                                    @click="store.state.objetive_color = color"
                                    class="color-picker rounded-circle" 
                                    :style="'background-color: '+color+';'"
                                    ></div>
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">
                                <div class="col-12 mt-3 mb-3 text-center">
                                    <button 
                                        @click="submit_objetive()"
                                        class="btn btn-success"
                                    >
                                        {{ store.state.objetive_id == '' ? "Criar Objetivo" : "Salvar Alterações" }}
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteObjetive" tabindex="-1" aria-labelledby="deleteObjetiveModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="col-11 mx-auto mt-4 mb-4">
                        <h5 class="mb-4">Você quer mesmo excluir esse objetivo?</h5>

                        <div class="col-12 col-lg-10 mx-auto text-start mt-3 mb-3">
                            <p>
                                <b>Exclusão permanente de objetivo</b>
                                <ul>
                                    <li>Não será possível recuperar este objetivo.</li>
                                </ul>
                            </p>
                            <p>
                                <b>Atenção!</b> Esta operação é irreversível!
                            </p>
                        </div>

                    </div>
                    <div class="col-12 text-center">
                        <button
                            id="confirmExclusionObjetiveButton" 
                            class="btn btn-danger"
                        >
                            Excluir permanentemente
                        </button>

                        <a @click="closeModal('deleteObjetive')" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="addTransaction" tabindex="-1" aria-labelledby="addTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">
                        <div class="col-10">
                            <b>
                                {{ store.state.transation.type == 'deposito' ? 'Novo' : 'Nova' }} {{ store.state.transation.type }} em {{ store.state.transation.objetive_name }}
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFieldsTransaction()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-12 col-lg-11 mx-auto mt-3 mb-3">

                            <div class="row">

                                <div class="col-6 mb-3">
                                    <label for="transactionAmount">
                                        Valor                                     
                                    </label>
                                    <input 
                                        type="tel" 
                                        class="form-control form-control-lg" 
                                        id="transactionAmount"
                                        name="transactionAmount"
                                        v-model.lazy="store.state.transaction_value.value" 
                                        v-money="money"
                                    >
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionDate">Data</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-lg" 
                                        id="transactionDate"
                                        name="transactionDate"
                                        v-model="store.state.transation.date"
                                    >
                                </div>

                                <div class="col-12 text-center mt-4 mb-n4">
                                    <button
                                        v-if="!store.state.savingTransaction"
                                        @click="submitTransaction()"
                                        class="btn"
                                        :class="'btn'+store.state.modalColor"
                                    >
                                        Salvar
                                    </button>
                                    <img v-if="store.state.savingTransaction" :src="loadGif" alt="" width="50">
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>