<script setup>
defineProps({
    objetive: {
        type: Object
    },
    dashboard:{
        type: Boolean,
        default: false,
    },
    darkmode: {
        type: Boolean,
        default: false
    }
});

/*function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}*/

const emit = defineEmits(['edit', 'setType', 'delete']);

const editObjetive = (data) => {

    emit('edit', data);

}

const setType = (data, type) => {

    emit('setType', data, type);

}

const deleteObjetive = (data) => {

    emit('delete', data);

}

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}

function calcPercent(target, balance){

    let percent = (parseFloat(balance) / parseFloat(target)) * 100;

    return percent;

}
</script>

<template>
    <div :id="'objetive'+ objetive.id" class="col-12 mb-3">

        <div class="row align-items-center row-objetives">

            <div class="col-2 col-lg-1 ms-2 me-2 me-lg-n3 text-center">
                <div class="text-center shadow icon icon-shape align-self-center rounded-circle" :style="'background-color: '+objetive.color">
                    <i :class="'fal ' + objetive.icon +' row-objetives-icons' " :style="'color:'+getContrastYIQ(objetive.color)"></i>
                </div>
            </div>
            
            <div class="col-4 col-lg-8">
                <div class="row align-items-center">
                    <div class="col-12">
                        
                        <p class="mb-1 font-weight-bold">
                            {{ objetive.name }}
                        </p>
                    </div>
                    
                </div>
            </div>
            
            <div class="col-5 col-lg-3 text-end">
                
                

                <a @click="editObjetive(objetive)" class="me-3 cursor-pointer" data-bs-toggle="modal" data-bs-target="#addObjetiveModal">
                    <i 
                        class="fal fa-pencil row-categories-icons-actions"
                        :class="darkmode ? 'text-white-50' : 'text-black-50'"
                        v-tooltip="{
                                    content:'Editar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>

                <a  @click="deleteObjetive(objetive)" class="me-3 cursor-pointer">
                    <i 
                        class="fal fa-trash text-danger row-categories-icons-actions"
                        v-tooltip="{
                            content:'Excluir',
                            distance: 15
                        }"
                    >
                    </i>
                </a>
            </div>

            <div class="col-12 mt-3 mb-2">

                <div class="row">
                    <div class="col-12 col-lg-8">
                        <div class="col-11 mx-auto mt-2 mb-1">
                            <div class="row">
                                <div class="col text-xs">
                                    Saldo
                                    <br>
                                    <span class="font-weight-bold" :class="darkmode ? 'text-white' : 'text-dark'">{{ formatMoney((parseFloat(objetive.balance))) }}</span>
                                </div>
                                <div class="col text-xs text-end">
                                    Objetivo
                                    <br>
                                    <span class="font-weight-bold" :class="darkmode ? 'text-white' : 'text-dark'">
                                        {{ formatMoney(objetive.target) }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-11 mx-auto mb-4">
                            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar" :style="'width: '+calcPercent(objetive.target, objetive.balance)+'%; background-color: '+objetive.color+''"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center text-lg-end">
                        <a data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType(objetive, 'deposito')" class="me-3 cursor-pointer btn btn-outline-success">
                            Depositar <i class="fas fa-arrow-circle-down"></i>
                        </a>
                        <a data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType(objetive, 'retirada')" class="me-lg-3 cursor-pointer btn" :class="darkmode ? 'btn-outline-warning' : 'btn-outline-dark'">
                            Retirar <i class="fas fa-arrow-circle-up"></i>
                        </a>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<style>
    .row-objetives{
        min-height: 85px;
    }

    .hover-objetive{
        border-bottom: solid 1px #dee2e6;
    }

    .hover-objetive:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-objetives-icons{
        font-size: 25px;
    }

    .row-objetives-icons-actions{
        font-size: 20px;
    }

    .color-paid {
        color: #2dce89 !important;
    }

    .objetive-description{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .sticky-top{
        z-index: 900 !important;
    }

    .objetive-taled{
        background-color: #faffb8;
    }
</style>