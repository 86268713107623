<script setup>
defineProps({
    card: {
        type: Object
    },
    darkmode: {
        type: Boolean,
        default: false
    }
    
});

const emit = defineEmits(['edit', 'archive', 'delete', 'open']);

const editCard = (data) => {
    emit('edit', data);
}

const archiveCard = (card, archive) => {

    emit('archive', card, archive);
}

const deleteCard = (card) => {

    emit('delete', card);
}

const openInvoice = (card) => {

    emit('open', card);

}

</script>

<template>
    <div class="col-11 mx-auto">

        <div class="row align-items-center row-accounts-list">

            <div class="col-2 col-lg-1 ms-3" @click="openInvoice(card.id)">
                <img :src="card.icon" alt="Banco Logo" width="50" class="rounded-circle">
            </div>
            <div class="col-4" @click="openInvoice(card.id)">
                <h6 class="ms-2">{{ card.name }}</h6>
            </div>
            <div class="col-5 col-lg-6 text-end">
                <a v-if="card.archived == 'No'" @click="editCard(card)" data-bs-toggle="modal" data-bs-target="#addCard" class="me-3">
                    <i 
                        class="fal fa-pencil row-accounts-list-icons-actions"
                        :class="darkmode ? 'text-white-50' : 'text-black-50'"
                        v-tooltip="{
                                    content:'Editar',
                                    distance: 15
                                }"
                        
                    >
                    </i>
                </a>
                <a v-if="card.archived == 'No'" @click="archiveCard(card, 'Yes')" class="me-3">
                    <i 
                        class="fal fa-inbox-in row-accounts-list-icons-actions"
                        :class="darkmode ? 'text-white-50' : 'text-black-50'"
                        v-tooltip="{
                                    content:'Arquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="card.archived == 'Yes'" @click="archiveCard(card, 'No')"  class="me-3">
                    <i 
                        class="fal fa-inbox-out row-accounts-list-icons-actions"
                        :class="darkmode ? 'text-white-50' : 'text-black-50'"
                        v-tooltip="{
                                    content:'Desarquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="card.archived == 'Yes'" @click="deleteCard(card)" class="me-3">
                    <i 
                        class="fal fa-trash text-danger row-accounts-list-icons-actions"
                        v-tooltip="{
                                    content:'Excluir',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
            </div>

        </div>

    </div>
</template>

<style>
    .row-accounts-list{
        height: 70px;
    }

    .row-accounts-list{
        border-bottom: solid 1px #dee2e6;
    }

    .row-accounts-list-icons{
        font-size: 25px;
    }

    .row-accounts-list-icons-actions{
        font-size: 20px;
    }
</style>