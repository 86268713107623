<script setup>

import { useStore } from "vuex";
import validSession from '../assets/js/valid-session';
import { onBeforeMount } from 'vue';
import loadGif from "@/assets/img/loading.gif";
import axios from "axios";
import { toast } from 'vue3-toastify';

const store = useStore();

onBeforeMount(() => {

    validSession.isLogged();

    store.state.message = {
        subject: '',
        description: ''
    };

    store.state.validation = {
        subjectHasError: false,
        subjectMessageError: '',
        descriptionHasError: false,
        descriptionMessageError: '',
    }

    store.state.sendingMessage = false;

});

const submitMessage = async () => {

    let data = new FormData();

    store.state.sendingMessage = true;

    store.state.subjectHasError = false;
    store.state.descriptionHasError = false;

    data.append('subject', store.state.message.subject);
    data.append('description', store.state.message.description);

    let url = '/support/send';
    let toast_message = 'Mensagem envianda! Você receberá nosso retorno no e-mail cadastrado.';

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'subject'){
                    store.state.validation.subjectHasError = true;
                    store.state.validation.subjectMessageError = value[1];
                }
                else if(value[0] == 'description'){
                    store.state.validation.descriptionHasError = true;
                    store.state.validation.descriptionMessageError = value[1];
                }


            });

            if(res.data.error == 'Não foi possível realizar enviar a mensagem no momento tente mais tarde!'){

                toast('Não foi possível realizar enviar a mensagem no momento tente mais tarde!', {
                    autoClose: 3000,
                    theme: "auto",
                    type: "error",
                    transition: "slide"
                });

            }

            store.state.sendingMessage = false;

        }
        else{

            store.state.validation = {
                subjectHasError: false,
                subjectMessageError: '',
                descriptionHasError: false,
                descriptionMessageError: '',
            }   

            store.state.message = {
                subject: '',
                description: ''
            };

            toast(toast_message, {
                autoClose: 3000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            store.state.sendingMessage = false;


        }

    })
    .catch(err => {
        toast('Não foi possível realizar a operação, tente mais tarde (Erro '+err.response.status+')', {
            autoClose: 1000,
            theme: "auto",
            type: "error",
            transition: "slide"
        });

        store.state.sendingMessage = false;
    })

}

</script>

<template>
     <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h6>Suporte</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">

                        <div class="row">
                            <div class="col-11 mx-auto mt-3 mb-3">
                                <label for="messageSubject">Assunto</label>
                                <input 
                                    type="text" 
                                    class="form-control form-control-lg" 
                                    id="messageSubject"
                                    name="messageSubject"
                                    placeholder="Assunto"
                                    v-model="store.state.message.subject"
                                    :class="store.state.validation.subjectHasError ? 'is-invalid' : ''"
                                >
                                <div id="messageSubjectFeedback" class="invalid-feedback">
                                    {{ store.state.validation.subjectMessageError }}
                                </div>
                            </div>
                            <div class="col-11 mx-auto">
                                <div class="col-12 mb-3">
                                    <label for="messageDescription">Descreva o problema</label>
                                    <textarea 
                                        id="messageDescription" 
                                        rows="4" 
                                        class="form-control" 
                                        v-model="store.state.message.description"
                                        :class="store.state.validation.descriptionHasError ? 'is-invalid' : ''"
                                    ></textarea>
                                    <div id="messageDescriptionFeedback" class="invalid-feedback">
                                    {{ store.state.validation.descriptionMessageError }}
                                </div>
                                </div>
                            </div>
                            <div class="col-12 text-center mt-3 mb-3">
                                    <button
                                        v-if="!store.state.sendingMessage"
                                        @click="submitMessage()"
                                        class="btn btn-success"
                                    >
                                        Solicitar suporte
                                    </button>
                                    <img v-if="store.state.sendingMessage" :src="loadGif" alt="" width="50">
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>