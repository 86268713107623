<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const sidebarMinimize = () => store.commit("sidebarMinimize");

const darkMode = computed(() => store.state.darkMode);

const minimizeSidebar = () => {
  if (window.innerWidth < 1200) {
    sidebarMinimize();
  }
};

defineProps({
  to: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
  navChilds: {
    type: Object,
    required: true
  },
  collapseId: {
    type: String,
    required: true
  },
});
</script>
<template>
    <a class="nav-link" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+collapseId" aria-expanded="false" aria-controls="{{ collapseId }}">
        <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
            >
                <slot name="icon"></slot>
            </div>
            <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{
                navText
            }}</span>
    </a>
    <div class="collapse collapse-background" :class="darkMode ? 'darkmode' : ''" :id="collapseId">
        <router-link 
            :to="child.to" 
            class="nav-link" 
            @click="minimizeSidebar"
            v-for="(child, index) in navChilds" :key="index"
        >
            <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
                :class="darkMode ? 'text-white' : 'text-dark'"
            >
                <i :class="darkMode ? 'fal '+ child.icon + ' text-sm opacity-10 text-white' : 'fal '+ child.icon + ' text-sm opacity-10 text-dark'"></i>
            </div>
            <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{
                child.name
            }}</span>
        </router-link>
    </div>
</template>

<style>
.collapse-background{
    background-color: #f6f6f6;
}

.collapse-background.darkmode{
    background-color: #18285e;
}
</style>
