<template>
    <VueInputCalculator enableKeyboard v-model="targetValue">
        <i class="fal fa-calculator"></i>
    </VueInputCalculator>
  </template>
  <script>
    import VueInputCalculator from "vue-input-calculator";
    export default {
      name: 'calculator.vue',
      components: {VueInputCalculator},
      props:[
        'value'
      ],
      mounted(){
        this.targetValue = this.value;
      },
      data() {
        return { targetValue: 0 }
      },
      methods:{
        updateValue(value){
            this.$emit('updateValue', value)
        }
      },
      watch:{
        targetValue:{
            handler(value){

                if(value > 0){
                    this.updateValue(value);
                }
                
            }
        }
      }
    }
  </script>