<script setup>
defineProps({
    objetive: {
        type: Object
    },
    darkmode: {
        type: Boolean,
        default: false
    }
});

/*function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}*/

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}

function calcPercent(target, balance){

    let percent = (parseFloat(balance) / parseFloat(target)) * 100;

    return percent;

}
</script>

<template>
    <div class="row align-items-center row-objetives">

        <div class="col-2 col-lg-1 ms-2 me-2 me-lg-3 text-center">
            <div class="text-center shadow icon icon-shape align-self-center rounded-circle" :style="'background-color: '+objetive.color">
                <i :class="'fal ' + objetive.icon +' row-objetives-icons' " :style="'color:'+getContrastYIQ(objetive.color)"></i>
            </div>
        </div>

        <div class="col-9">
            <p class="mb-1 text-dark font-weight-bold">
                {{ objetive.name }}
            </p>
        </div>

        <div class="col-12 mt-3 mb-2">

            <div class="row">
                <div class="col-12">
                    <div class="col-11 mx-auto mt-2 mb-1">
                        <div class="row">
                            <div class="col text-xs">
                                Saldo
                                <br>
                                <span class="font-weight-bold" :class="darkmode ? 'text-white' : 'text-dark '">{{ formatMoney((parseFloat(objetive.balance))) }}</span>
                            </div>
                            <div class="col text-xs text-end">
                                Objetivo
                                <br>
                                <span class="font-weight-bold" :class="darkmode ? 'text-white' : 'text-dark '">
                                    {{ formatMoney(objetive.target) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-11 mx-auto mb-4">
                        <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar" :style="'width: '+calcPercent(objetive.target, objetive.balance)+'%; background-color: '+objetive.color+''"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<style>
    .row-objetives{
        min-height: 85px;
    }

    .hover-objetive{
        border-bottom: solid 1px #dee2e6;
    }

    .hover-objetive:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-objetives-icons{
        font-size: 25px;
    }

    .row-objetives-icons-actions{
        font-size: 20px;
    }

    .color-paid {
        color: #2dce89 !important;
    }

    .objetive-description{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .sticky-top{
        z-index: 900 !important;
    }

    .objetive-taled{
        background-color: #faffb8;
    }
</style>