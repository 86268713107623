import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import TransactionsList from "../views/TransactionsList.vue";
import Categories from "../views/Categories.vue";
import AccountsList from "../views/AccountsList.vue";
import CreditCardList from '../views/CreditCardList.vue';
import InvoiceList from "../views/InvoiceList.vue";
import CardDetails from "../views/CardDetails.vue";
import Privacity from "../views/Privacity.vue";
import Support from "../views/Support.vue";
import Objetives from "../views/Objetives.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/politica-de-privacidade",
    name: "Politica de Privacidade",
    component: Privacity
  },
  {
    path: "/inicio",
    name: "Visão Geral",
    component: Dashboard,
  },
  {
    path:'/lancamentos',
    name:'Lançamentos',
    component:TransactionsList
  },
  {
    path:'/fatura/:id',
    name:'Fatura',
    component:InvoiceList
  },
  {
    path:'/cartao-de-credito/:id',
    name:'Cartão de crédito',
    component:CardDetails
  },
  {
    path:'/configuracoes/categorias',
    name:'Categorias',
    component:Categories
  },
  {
    path:'/configuracoes/contas',
    name:'Contas',
    component:AccountsList
  },
  {
    path:'/configuracoes/cartoes-de-credito',
    name:'Cartões de crédito',
    component:CreditCardList
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/minha-conta",
    name: "Minha Conta",
    component: Profile,
  },
  {
    path: "/login",
    name: "Login",
    component: Signin,
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: Signup,
  },
  {
    path: "/suporte",
    name: "Suporte",
    component: Support,
  },
  {
    path: "/objetivos",
    name: "Objetivos",
    component: Objetives,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
