<script setup>

import CreditCardRow from './components/CreditCardRow.vue';
import { onBeforeMount, onMounted } from "vue";
import setTooltip from "../assets/js/tooltip";
import router from "../router";
import cookie from '../assets/js/cookies.js';
import validSession from "../assets/js/valid-session.js";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { useStore } from "vuex";
import axios from "axios";
import { toast } from 'vue3-toastify';
import loadGif from "@/assets/img/loading.gif"
import SkeletonLoad from './components/SkeletonLoad.vue';
import { computed } from "vue";

const getIcons = async () => {

    axios.get('bank-icons?type=credit')
    .then(res => {
        
        if(res.status == 200){

            store.state.icons = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

const value_limit = {
    value: 0
};

const money = {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }

onBeforeMount(() => {

    validSession.isLogged();

    store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
        accountHasError: false,
        accountMessageError: '',
    };

    store.state.card = {
        icon: 'https://assets.agive.com.br/financas/cartoes/credit-card-logo.png',
        name: '',
        limit: 0,
        close_day: 1,
        due_day: 1,
        account: "",
        id: ''
    };

    store.state.card_details = {
        icon: '',
        name: '',
        limit: 0,
        limit_used: 0,
        close_day: 1,
        due_day: 1,
        account: "",
        id: ''
    };

    store.state.icons = [];
    store.state.accounts = [];
    store.state.cards = [];
    store.state.invoices = [];
    store.state.qtdInvoices = 0;
    store.state.loadingCards = true;
    store.state.savingCard = false;
    store.state.editBalance = false;

    store.state.toArchive = {
        icon: '',
        name: '',
    };

    store.state.selectedYear = new Date().getFullYear();

    getIcons();
    getCards();
    getAccounts();
  
})

onMounted(() => {
    setTooltip();

    let session = cookie.get('user_agive_session');

    if(session == null){
        router.push({name: 'Login'});
    }

});

const clearFields = () => {

    value_limit.value = 0;

    store.state.card = {
        icon: 'https://assets.agive.com.br/financas/cartoes/credit-card-logo.png',
        name: '',
        not_sum: false,
        id: '',
        balance: 0
    };

    store.state.editBalance = false;

    store.state.toArchive = {
        icon: '',
        name: '',
    };
}

const getAccounts = async () => {

    store.state.loadingCards = true;

    axios.get('accounts')
    .then(res => {
        
        if(res.status == 200){

            store.state.accounts = res.data;
            store.state.loadingCards = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getCards = async () => {

    store.state.loadingCards = true;

    axios.get('credit-cards')
    .then(res => {
        
        if(res.status == 200){

            store.state.cards = res.data;
            store.state.loadingCards = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const submitCard = async () => {

    store.state.savingCard = true;

    let data = new FormData();

    store.state.validation.nameHasError = false;

    store.state.card.limit = value_limit.value.replace('R$ ', '');

    data.append('name', store.state.card.name);
    data.append('icon', store.state.card.icon);
    data.append('limit', store.state.card.limit);
    data.append('close_day', store.state.card.close_day);
    data.append('due_day', store.state.card.due_day);
    data.append('account_id', store.state.card.account);

    let url = '/credit-cards';
    let toast_message = 'Cartão de crédito criado com sucesso!';

    if(store.state.card.id != ''){

        url = '/credit-cards/update/'+store.state.card.id;
        toast_message = 'Cartão de crédito atualizado com sucesso!';
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'name'){
                    store.state.validation.nameHasError = true;
                    store.state.validation.nameMessageError = value[1];
                }

                if(value[0] == 'account_id'){
                    store.state.validation.accountHasError = true;
                    store.state.validation.accountMessageError = value[1];
                }

            })

            store.state.savingCard = false;

        }
        else{

            store.state.validation = {
                nameHasError: false,
                nameMessageError: '',
            };

            closeModal('addCard');

            clearFields();

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            store.state.savingCard = false;

            getCards();

        }

    })
    .catch(err => {
        console.error(err); 

        toast('Não foi possível realizar a operação, tente mais tarde (Erro '+err.response.status+')', {
            autoClose: 1000,
            theme: "auto",
            type: "error",
            transition: "slide"
        });

        store.state.savingCard = false;
    })

}

const editCard = (data) => {

    value_limit.value = formatMoney(data.limit);

    store.state.card = {
        icon: data.icon,
        name: data.name,
        limit: formatMoney(data.limit),
        close_day: data.close_day,
        due_day: data.due_day,
        account: data.account_id,
        id: data.id
    };

}

const archiveCard = async (card, archive) => {

    if(archive == 'Yes'){

        store.state.toArchive = {
            icon: card.icon,
            name: card.name,
        };

        openModal('confirmarchiveCard');

        var confirmButton = document.getElementById('confirmarchiveCardButton');
        confirmButton.addEventListener('click', async function () {
        // do something...
        
            let data = new FormData();

            data.append('archived', archive);

            let url = '/credit-cards/archive/'+card.id;
            let toast_message = 'Cartãp de crédito arquivado com sucesso!';

            await axios.post(url, data)
            .then(res => {

                if(res.data.error){

                    console.log(res);

                }
                else{

                    toast(toast_message, {
                        autoClose: 1000,
                        theme: "auto",
                        type: "success",
                        transition: "slide"
                    });

                    closeModal('confirmarchiveCard');
                    
                    getCards();

                }

            })
            .catch(err => {
                console.error(err); 
            })
            

        })

    }
    else{

        let data = new FormData();

        data.append('archived', archive);

        let url = '/credit-cards/archive/'+card.id;
        let toast_message = 'Conta desarquivada com sucesso!';

        await axios.post(url, data)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('confirmarchiveCard');
                
                getCards();

            }

        })
        .catch(err => {
            console.error(err); 
        })

    }    

}

const deleteCard = async (card) => {

    store.state.toArchive = {
        icon: card.icon,
        name: card.name,
    };

    openModal('deleteCard');

    var confirmButton = document.getElementById('confirmExclusionAccountButton');
    confirmButton.addEventListener('click', async function () {
    // do something...

        let url = '/credit-cards/delete/'+card.id;
        let toast_message = 'Cartão excluído com sucesso!';

        await axios.post(url)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('deleteCard');
                
                getCards();

            }

        })
        .catch(err => {
            console.error(err); 
        })
        

    })

}

const openInvoices = async(card) => {

    location.href = '/cartao-de-credito/'+card;

}

const openModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.show();

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

function formatMoney(v) {
    let total = parseFloat(v);

    return total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
}

</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row align-items-center">
                            <div class="col-8 col-lg-6">
                                <h6>Cartões de crédito</h6>
                            </div>
                            <div class="col-4 col-lg-6 text-end">
                                <a 
                                    class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" 
                                    type="button" 
                                    id="dropdownMenuButton1"
                                    @click="openModal('addCard')"
                                    >
                                    <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">

                        <div class="col-12">

                            <div class="row">

                                <div class="col-11 mx-auto text-center mt-4 mb-4" v-if="store.state.loadingCards">
                                    <div class="row align-items-center">

                                        <div class="col-2 col-lg-1 ms-3">
                                            <SkeletonLoad
                                                :width='"50px"'
                                                :height="'50px'"
                                                :class="'rounded'"
                                            />
                                        </div>
                                        <div class="col-4">
                                            <SkeletonLoad
                                                :width="'150px'"
                                                :height="'20px'"
                                            />
                                        </div>
                                        <div class="col-5 col-lg-6 text-end">
                                            
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 text-center mt-4" v-if="store.state.cards.length == 0 && !store.state.loadingCards">
                                    <p>Nenhum cartão de crédito cadastrado.</p>
                                </div>
                                <div class="col-12 mt-4 mb-4" v-if="!store.state.loadingCards">

                                    <CreditCardRow
                                        v-for="(card, index) in store.state.cards" :key="index"
                                        :card="card"
                                        :darkmode="darkMode"
                                        @edit="editCard"
                                        @archive="archiveCard"
                                        @delete="deleteCard"
                                        @open="openInvoices"
                                    />

                                </div>

                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="addCard" tabindex="-1" aria-labelledby="addCard" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">

                        <div class="col-10">
                            <b>
                                Novo cartão de crédito
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-11 mx-auto">

                            <div class="row mt-4 mb-4">

                                <div class="col-3 account-create-icon-div">

                                    <img :src="store.state.card.icon" alt="" class="img-fluid rounded-circle">

                                </div>
                                <div class="col-9">
                                    <div class="row">

                                        <div class="col-12 mb-3">
                                            <label for="cardName">Nome do cartão</label>
                                            <input 
                                                type="text" 
                                                class="form-control form-control-lg" 
                                                id="cardName"
                                                name="cardName"
                                                placeholder="Nome do cartão"
                                                v-model="store.state.card.name"
                                                :class="store.state.validation.nameHasError ? 'is-invalid' : ''"
                                            >
                                            <div id="cardNameFeedback" class="invalid-feedback">
                                                {{ store.state.validation.nameMessageError }}
                                            </div>
                                        </div>

                                        <div class="col-12 mb-3">
                                            <label for="cardLimit">Limite do cartão</label>
                                            <input 
                                                type="tel" 
                                                class="form-control form-control-lg" 
                                                id="cardLimit"
                                                name="cardLimit"
                                                placeholder="Limite do cartão"
                                                v-model.lazy="value_limit.value" 
                                                v-money="money"
                                            >
                                        </div>

                                        <div class="col-6">
                                            <label for="cardCloseDay">Fecha dia</label>
                                            <select class="form-control form-control-lg"
                                                v-model="store.state.card.close_day"
                                            >
                                                <option v-for="n in 31" :key="n"
                                                    :value="n"
                                                >
                                                    {{n}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="cardDueDay">Vence dia</label>
                                            <select class="form-control form-control-lg"
                                                v-model="store.state.card.due_day"
                                            >
                                                <option v-for="n in 31" :key="n"
                                                    :value="n"
                                                >
                                                    {{n}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-12 mt-3">
                                            <label for="cardAcount">Conta que o cartão está vinculado</label>
                                            <select class="form-control form-control-lg"
                                                v-model="store.state.card.account"
                                                :class="store.state.validation.accountHasError ? 'is-invalid' : ''"
                                            >
                                                <option value="" selected disabled>Selecione a conta</option>
                                                <option v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id"
                                                >
                                                   {{account.name}}
                                                </option>
                                            </select>
                                            <div id="cardAcountFeedback" class="invalid-feedback">
                                                {{ store.state.validation.accountMessageError }}
                                            </div>
                                        </div>

                                    </div>
                                </div>                            

                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha um icone</p>
                                </div>

                                <div class="col-12 icon-list">

                                    <div class="row justify-content-center">

                                        <div
                                            v-for="(icon, index) in store.state.icons" :key="index"
                                            @click="store.state.card.icon = icon.path"
                                            class="icon-picker d-flex align-items-center text-dark" 
                                            :title="icon.name"
                                        >
                                            <img :src="icon.path" :alt="icon.name" class="rounded-circle">
                                        </div>

                                    </div>

                                </div>
                                
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">
                                
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-center">
                                    <button
                                        v-if="!store.state.savingCard"
                                        @click="submitCard()"
                                        class="btn btn-success"
                                    >
                                    {{ store.state.card.id == '' ? "Adicionar cartão" : "Salvar Alterações" }}
                                    </button>
                                    <img v-if="store.state.savingCard" :src="loadGif" alt="" width="50">
                                </div>
                            </div>

                        </div>

                        

                        

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="confirmarchiveCard" tabindex="-1" aria-labelledby="confirmarchiveCard" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">

                        <div class="col-11 mx-auto mt-4 mb-4">
                            <h5 class="mb-4">Você quer mesmo arquivar esse cartão?</h5>

                            <div class="col-12 text-center mt-3 mb-3">
                                <img :src="store.state.toArchive.icon" alt="" width="60" class="rounded">
                                <br>
                                <h4>{{store.state.toArchive.name}}</h4>
                            </div>

                            <div class="col-12 col-lg-10 mx-auto text-start mt-3 mb-3">
                                <p>
                                    <b>Não será mais possível</b>
                                    <ul>
                                        <li>Incluir novas transações</li>
                                        <li>Emitir relatórios</li>
                                        <li>Fazer filtros</li>
                                    </ul>
                                </p>
                                <p>
                                    Apesar disso, todas as transações continuarão disponíveis para manutenção do histórico financeiro.
                                    <br>
                                    <br>
                                    Você poderá reverter o arquivamento a qualquer momento.
                                </p>
                            </div>

                        </div>
                        <div class="col-12 text-center">
                            <button
                                id="confirmarchiveCardButton" 
                                class="btn btn-success"
                            >
                                Arquivar cartão
                            </button>

                            <a @click="closeModal('confirmarchiveCard')" class="btn btn-link">Cancelar</a>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteCard" tabindex="-1" aria-labelledby="deleteCategoryModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="col-11 mx-auto mt-4 mb-4">
                        <h5 class="mb-4">Você quer mesmo excluir esse cartão?</h5>

                        <div class="col-12 text-center mt-3 mb-3">
                            <img :src="store.state.toArchive.icon" alt="" width="60" class="rounded">
                            <br>
                            <h4>{{store.state.toArchive.name}}</h4>
                        </div>

                        <div class="col-12 col-lg-10 mx-auto text-start mt-3 mb-3">
                            <p>
                                <b>Exclusão permanente de conta</b>
                                <ul>
                                    <li>Exclui todos os lançamentos feitos nesse cartão;</li>
                                    <li>Todo este histórico financeiro será excluído;</li>
                                    <li>Não será possível recuperar este cartão.</li>
                                </ul>
                            </p>
                            <p>
                                <b>Atenção!</b> Esta operação é irreversível!
                            </p>
                        </div>

                    </div>
                    <div class="col-12 text-center">
                        <button
                            id="confirmExclusionAccountButton" 
                            class="btn btn-danger"
                        >
                            Excluir permanentemente
                        </button>

                        <a @click="closeModal('deleteCard')" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</template>

<style>

    .account-create-icon-div{
        height: 50px;
    }

    .account-create-icon{
        min-height: 100%;
    }

    .icon-picker{
        margin: 2px 4px 2px 0;
        width: 60px !important;
        height: 60px !important;
        cursor: pointer;
    }

    .icon-picker img {
        width: 100%;
        border-radius: 0.25rem
    }

    .icon-create{
        font-size: 30px;
    }

    .icon-list{
        height: 150px;
        overflow-x:auto;
    }

    .cursor-pointer{
        cursor: pointer;
    }

    @media (max-width: 575.98px) {

        .category-create-icon-div{
            height: 60px;
        }
    }
</style>